import { ComponentType } from "react";
import { CONSTANTS } from "consts";

// All components for pre-routes
import WrapplerAllMain from "./Wrapper/WrapplerAllMain";
import Login from "Screens/Login/Login";
import ForgotPassword from "Screens/ForgotPassword/ForgotPassword";
import PageNotFound from "Components/NotFoundPage";
import ProtectedRoute from "./ProtectedRoute";

// All components for workout history
import WrapperWorkoutHistory from "./Wrapper/WrapperWorkoutHistory";
import WorkoutHistoryDetail from "Screens/WorkoutHistory/Detail";
import ComparePeriod from "Screens/WorkoutHistory/ComparePeriod";
import Achievements from "Screens/WorkoutHistory/Achievements";
import WorkoutHistoryMain from "Screens/WorkoutHistory/Main";

// All components for membership
import WrapperMembership from "./Wrapper/WrapperMembership";
import MembershipDetail from "Screens/Memberships/Detail";
import MembershipMain from "Screens/Memberships/Main";

// All components for order
import WrapperOrder from "./Wrapper/WrapperOrder";
import OrderMain from "Screens/Orders/Main";
import OrderDetail from "Screens/Orders/Detail";

// All components remaining
import Profile from "Screens/Profile";
import PaymentMethodMain from "Screens/PaymentMethod/Main";
import IntegrationMain from "Screens/Integrations/Main";
import Strava from "Screens/Strava";
import Logbook from "Screens/Logbook";
import BetaCode from "Screens/BetaCode";
import MembershipDetailForC2 from "Screens/Memberships/DetailForC2";
import MembershipList from "Screens/Memberships/MembershipInviteList";
import MembershipFamily from "Screens/Memberships/MembershipFamily";
import MembershipInviteConfirm from "Screens/Memberships/MembershipInviteConfirm";
import MachineManage from 'Screens/Memberships/MachineManage'

export interface IRouteItem {
    path: string;
    element: ComponentType<any>;
    protectedRoute?: ComponentType<any>;
    exact?: boolean;
    children?: IRouteItem[];
}

export interface IRedirectRoute {
    path: string;
    redirectTo: string;
}

export const allPreRoutes: IRouteItem[] = [
    {
        path: CONSTANTS.NAVIGATION_URL.LOGIN,
        element: Login,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.FORGOT_PASSWORD,
        element: ForgotPassword,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.NOT_FOUND,
        element: PageNotFound,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.HOME,
        protectedRoute: ProtectedRoute,
        element: WrapplerAllMain,
    },
];

export const allMainRoutes: IRouteItem[] = [
    {
        path: CONSTANTS.NAVIGATION_URL.HOME,
        element: Profile,
        exact: true,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.PROFILE,
        element: Profile,
        exact: true,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY,
        element: WrapperWorkoutHistory,
        // Define sub-routes for Workout History
        children: [
            {
                path: CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_DETAIL,
                element: WorkoutHistoryDetail,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_COMPARE_PERIOD,
                element: ComparePeriod,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_ACHIEVEMENTS,
                element: Achievements,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY,
                element: WorkoutHistoryMain,
                exact: true,
            }
        ]
    },
    {
        path: CONSTANTS.NAVIGATION_URL.MEMBERSHIPS,
        element: WrapperMembership,
        // Define sub-routes for Memberships
        children: [
            {
                path: CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_CONFIRM,
                element: MembershipInviteConfirm,
                exact: true,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.MEMBERSHIPS,
                element: MembershipMain,
                exact: true,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.MEMBERSHIP_DETAIL_APPLE,
                element: MembershipDetailForC2,
                exact: true,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.MEMBERSHIP_DETAIL,
                element: MembershipDetail,
                exact: true,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_LIST,
                element: MembershipList,
                exact: true,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.MEMBERSHIP_FAMILLY,
                element: MembershipFamily,
                exact: true,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.MEMBERSHIP_MACHINE_MANAGE,
                element: MachineManage,
                exact: false,
            },
        ],
    },
    {
        path: CONSTANTS.NAVIGATION_URL.ORDERS,
        element: WrapperOrder,
        // Define sub-routes for Orders
        children: [
            {
                path: CONSTANTS.NAVIGATION_URL.ORDER_DETAIL,
                element: OrderDetail,
            },
            {
                path: CONSTANTS.NAVIGATION_URL.ORDERS,
                element: OrderMain,
                exact: true,
            },
        ],
    },
    {
        path: CONSTANTS.NAVIGATION_URL.PAYMENT_METHOD,
        element: PaymentMethodMain,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.INTEGRATIONS,
        element: IntegrationMain,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.STRAVA,
        element: Strava,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.LOGBOOK,
        element: Logbook,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.BETA_CODE,
        element: BetaCode,
    },
    {
        path: CONSTANTS.NAVIGATION_URL.NOT_FOUND,
        element: PageNotFound,
    },
];

export const allRedirectRoutes: IRedirectRoute[] = [
    {
        path: CONSTANTS.REDIRECT_URL.PROFILE_BASIC_INFO,
        redirectTo: CONSTANTS.NAVIGATION_URL.PROFILE,
    },
    {
        path: CONSTANTS.REDIRECT_URL.SUBSCRIPTION_OVERVIEW,
        redirectTo: CONSTANTS.NAVIGATION_URL.MEMBERSHIPS,
    },
    {
        path: CONSTANTS.REDIRECT_URL.SUBSCRIPTION_RECEIPT,
        redirectTo: CONSTANTS.NAVIGATION_URL.ORDERS,
    },
    {
        path: CONSTANTS.REDIRECT_URL.SUBSCRIPTION_BILLING_INFO,
        redirectTo: CONSTANTS.NAVIGATION_URL.PAYMENT_METHOD,
    }
];
